import ContentLoader from 'react-content-loader';
import { nafColor } from '@nafcore/theme';
import React from 'react';
import styled from 'styled-components';

export const OneLineLoader = ({ width, height }: { width: string; height: string }) => (
  <Wrapper height={height}>
    <ContentLoader
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      preserveAspectRatio="xMidYMid meet"
      backgroundColor={nafColor.neutral.neutral1}
      foregroundColor={nafColor.neutral.neutral2}
      uniqueKey="breadcrumb-loader"
    >
      <rect x="0" y="0" rx="0" ry="0" width={width} height={height} />
    </ContentLoader>
  </Wrapper>
);

const Wrapper = styled.span<{ height: string }>`
  overflow: hidden;
  height: ${({ height }) => height}px;
`;
